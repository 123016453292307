import store from '@store'

function translationOrNull (namespace, key) {
  const translation = store.state.i18n.translations[namespace][key]

  return typeof translation === 'string' ? translation : null
}

const allTranslationsFromKeys = (id, namespace, keys) => keys
  .reduce((obj, key) => Object.assign(obj, {[key]: translationOrNull(namespace, `${id}_${key}`)}), {})

export const getCropTranslations = id => allTranslationsFromKeys(id, 'masterData.crops.crop', ['name'])

export const getCropUsageTranslations = (id, useFallbackAbbreviation) => {
  const translations = allTranslationsFromKeys(id, 'masterData.crops.usage', ['name', 'abbreviation'])
  if (useFallbackAbbreviation && !translations.abbreviation) {
    translations.abbreviation = translations.name.substring(0, 3)
  }
  return translations
}

export const getCropHarvestQualityTranslations = id => allTranslationsFromKeys(id, 'masterData.crops.quality', ['name'])
