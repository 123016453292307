<template lang="html">
  <div style="position: relative">
    <!--<wip todo>FI-6151: Einheitliche Überschrift und required Hinweis</wip>-->
    <form class="form">
      <div class="form-group">
        <TextInput
          v-model="fieldName" name="name"
          required
          :placeholder="SR_FieldRecordSystem.NewField"
        >
          <template #label>
            <IxRes>Common.SR_Common.Name</IxRes><span>:</span>
          </template>
        </TextInput>
      </div>

      <div class="form-group" style="width: 25%;">
        <TextInput v-model="code" name="code">
          <template #label>
            <IxRes>Common.SR_Common.FieldNumber</IxRes><span>:</span>
          </template>
        </TextInput>
      </div>

      <div class="form-group" style="width: 60%;">
        <VueSelectize
          v-model="fieldUsage" required
          allow-empty
          placeholder="Bitte Nutzungsart auswählen"
          name="usageType" :options="usageTypes"
          sort-field="text" :fields="fieldUsageSelectizeFields"
        >
          <template #label>
            <IxRes>Common.SR_Common.TypeOfUse</IxRes><span>:</span>
          </template>
        </VueSelectize>
      </div>

      <div class="form-group protection-zone">
        <BsRadioList v-model="waterProtectionZone" :options="[0, 1, 2, 3]">
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.WaterProtectionZone</IxRes>
          </template>
          <template #default="{option}">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.WaterProtectionZone_{{ option }}</IxRes>
          </template>
        </BsRadioList>
        <BsCheckbox v-model="natura2000">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.Natura2000</IxRes>
        </BsCheckbox>
      </div>

      <div class="form-group erosion-hazard-zones">
        <BsRadioList v-model="waterErosion" :options="[0, 1, 2]">
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ErosionHazardZone</IxRes>
          </template>
          <template #default="{option}">
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.ErosionHazardZone_{{ option }}</IxRes>
          </template>
        </BsRadioList>
        <BsCheckbox v-model="windErosion">
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.WindErosion</IxRes>
        </BsCheckbox>
      </div>

      <div class="form-group field-areas">
        <NumericInput
          v-model="official"
          format="f4"
          unit="ha"
          name="officialArea"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.OfficialArea</IxRes><span>:</span>
          </template>
        </NumericInput>
      </div>
      <div class="form-group field-areas">
        <NumericInput
          v-model="cultivated" name="cultivated"
          format="f4"
          unit="ha"
          :rule="min(0)"
          :description="SR_FieldRecordSystem.DescriptionForCultivatedArea"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CultivatedArea</IxRes><span>:</span>
          </template>
        </NumericInput>
      </div>

      <div class="form-group">
        <VueSelectize
          v-model="cropRotationId"
          class="form-group"
          allow-empty
          :options="cropRotationIds"
          :fields="cropRotationIdSelectizeFields"
          sort-field="text"
          :placeholder="SR_Common.EmptyCropRotation"
          name="cropRotation"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.AssignCropRotation</IxRes><span>:</span>
          </template>
          <template #description>
            <span><IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.DescriptionForCreationCropRotations</IxRes>:</span>
          </template>
        </VueSelectize>
        <span v-if="cropRotationIds.length === 0"><i class="fa fa-exclamation-triangle" aria-hidden="true" />
          <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoCropRotationsDeposited</IxRes>
        </span>
      </div>

      <div class="form-buttons">
        <IxButton
          large :disabled="canSave"
          submit
          icon="floppy-o" @click="onSaveClick"
        >
          <IxRes>Common.SR_Common.Save</IxRes>
        </IxButton>
        <IxButton
          large cancel
          icon="trash"
          @click="cancel"
        />
      </div>
    </form>
    <FrsLoadingIndicator :loading="loading || saving" />
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import {mapResources, mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'
import {getCropUsageTranslations} from '@frs/helpers/crops'

import BsCheckbox from '@components/bootstrap/BsCheckbox'
import BsRadioList from '@components/bootstrap/BsRadioList'
import VueSelectize from '@components/VueSelectize'
import NumericInput from '@components/forms/NumericInput'
import TextInput from '@components/forms/TextInput'
import IxButton from '@components/IxButton'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import FormPartMixin from '@components/forms/FormPartMixin'

export default {
  components: {
    VueSelectize,
    NumericInput,
    TextInput,
    IxButton,
    FrsLoadingIndicator,
    BsRadioList,
    BsCheckbox
  },
  mixins: [
    FormPartMixin,
    StandardRulesMixin
  ],
  data () {
    return {
      loading: false,
      saving: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/fieldDetails/editBuffer', [
      'code',
      'fieldUsage',
      'cropRotationId',
      'waterProtectionZone',
      'natura2000',
      'waterErosion',
      'windErosion'
    ]),
    ...mapFormFields('fieldRecordSystem/fieldDetails/editBuffer', {
      'fieldName': {
        read: (value, state) => state.name,
        mutation: 'setName'
      },
      'area.cultivated': {
        read: value => value / 10000,
        write: value => value * 10000
      },
      'area.official': {
        read: value => value / 10000,
        write: value => value * 10000
      }
    }),
    ...mapResources([
      'Common.SR_Common',
      'Messages.State.SR_StateMessages',
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem', {
      assignedCropRotations: state => state.cropRotations.company,
      fieldDetails: state => state.data.field,
      usageTypesObjects: state => state.data.usageTypes,
      harvestYear: state => state.userSettings.harvestYear
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    fieldUsageSelectizeFields () {
      return {
        text: name => this.SR_FieldRecordSystem[`FieldUsage_${name}`]
      }
    },
    usageTypes () {
      return this.usageTypesObjects.map(x => x.name)
    },
    cropRotationLookup () {
      const lookup = {}
      this.assignedCropRotations.forEach(rotation => {
        lookup[rotation.id] = rotation
      })
      return lookup
    },
    cropRotationIds () {
      return this.assignedCropRotations.map(x => x.id)
    },
    cropRotationIdSelectizeFields () {
      return {
        text: id => this.cropRotationLookup[id].name,
        label: id => this.formatCropRotationSteps(this.cropRotationLookup[id])
      }
    },
    disableSaveButton () {
      return this.state !== 'success'
    },
    canSave () {
      return !(this.fieldName !== '' && this.fieldUsage)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'loadFullFieldData'
    ]),
    ...mapActions('fieldRecordSystem/fieldDetails', [
      'save',
      'reset'
    ]),
    formatCropRotationSteps (rotation) {
      return rotation.steps.map(step => {
        const {abbreviation} = getCropUsageTranslations(step.cropUsageId, true)

        return abbreviation
      }).join('-')
    },
    onSaveClick () {
      this.saving = true

      this.validate()

      // nextTick to get computed updates for state
      this.$nextTick()
        .then(() => {
          if (this.state !== 'success') return

          return this.save()
            .then(fieldId => {
              this.$router.push({name: 'fieldDashboard', params: {fieldId}})
              notifications.success(this.SR_StateMessages.SaveSuccess)
            })
            .catch(error => {
              if (error.response.data.error === 'assignParentCropRotation') {
                notifications.error(error.response.data.message)
                console.error(`${error.response.data.message}, fieldId: ${error.response.data.fieldId}, cropRotationId: ${error.response.data.cropRotationId}`)
              } else {
                notifications.error(this.SR_StateMessages.SaveError)
                console.error(error)
              }
            })
        })
        .then((id) => {
          this.saving = false
        })
    },
    cancel () {
      if (this.$route.name === 'basicFieldInfoForm') {
        this.$router.push({name: 'fieldDashboard'})
      } else {
        this.$router.push({name: 'orgDashboard'})
      }
    },
    async prepareData (fieldId) {
      this.loading = true
      if (fieldId) {
        await this.loadFullFieldData(fieldId)
      }
      await this.reset(fieldId)
      this.loading = false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.$store.commit('fieldRecordSystem/setRightView', 'default')
      await vm.prepareData(to.params.fieldId)
    })
  },
  async beforeRouteUpdate (to, from, next) {
    next()
    await this.prepareData(to.params.fieldId)
  }
}
</script>

<style>
.field-areas .input-field {
  width: 30%;
}
</style>

<style lang="scss" scoped>
.form-buttons {
  display: flex;
  justify-content: space-between;
}
div.alert {
  padding: 5px;
  height: 34px;
}

.protection-zone, .erosion-hazard-zones {
  display: flex;
  flex-direction: column;
}
</style>
